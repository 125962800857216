<template>
  <div id="container">
    <div class="marker-detail-box">
      <h4>换电柜实时位置详情</h4>
      <ul>
        <li>换电柜名称: {{ cabinetInfo.cabinetName }}</li>
        <li>换电柜编号: {{ cabinetInfo.cabinetCode }}</li>
        <li>时间: {{ cabinetInfo.updateTime }}</li>
        <li>位置：{{ cabinetInfo.address }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import AMap from 'AMap'
import * as chargeCabinetApi from '@/api/rnt/chargeCabinet'
var map, marker
export default {
  name: 'markerDetail',
  data() {
    return {
      markerData: {},
      cabinetCode: '',
      latitude: '',
      longitude: '',
      cabinetInfo: {}
      // map: null,
      // marker: null
    }
  },
  created() {
    if (this.$route.query.cabinetCode) {
      this.cabinetCode = this.$route.query.cabinetCode
    }
  },
  watch: {
    $route: 'getQuery',
    cabinetCode: 'init'
  },
  methods: {
    // 换电柜编码改变初始化页面
    init() {
      this.getMarker()
    },
    // 获取路由参数
    getQuery() {
      if (this.$route.query.cabinetCode) {
        this.cabinetCode = this.$route.query.cabinetCode
      }
    },
    // 获取换电柜信息
    async getCabinetInfo() {
      await chargeCabinetApi
        .page({ cabinetCode: this.cabinetCode })
        .then((res) => {
          if (res.code === 0) {
            this.cabinetInfo = res.data[0]
            if (marker) {
              map.remove(marker)
            }
            if (res.data[0].latitude && res.data[0].longitude) {
              this.latitude = res.data[0].latitude
              this.longitude = res.data[0].longitude
            } else {
              this.latitude = ''
              this.longitude = ''
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 初始化地图数据
    initMapData() {
      map = new AMap.Map('container', {
        center: [118.08923, 24.479406],
        resizeEnable: true,
        zoom: 10
      })
    },
    // 获取点位信息并加载到map
    async getMarker() {
      await this.getCabinetInfo()
      const that = this
      if (that.latitude && that.longitude) {
        // const zoom = this.map.getZoom()
        // const sizeNum = zoom ? (Number(zoom) / 2) * 6 : 25
        const chargeCabinetIcon = new AMap.Icon({
          size: new AMap.Size(40, 40), // 图标尺寸
          image: '/static/chargeCabinet.png', // Icon的图像
          imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
          imageSize: new AMap.Size(40, 40) // 根据所设置的大小拉伸或压缩图片
        })
        const lnglat = [that.longitude, that.latitude]
        marker = new AMap.Marker({
          position: lnglat,
          icon: chargeCabinetIcon,
          extData: {
            type: 'chargeCabinet'
          }
        })
        map.add(marker)
        map.setFitView()
      }
    }
  },
  mounted() {
    this.initMapData()
  }
}
</script>

<style lang='less' scoped>
#container {
  height: 100vh;
}
.marker-detail-box {
  background-color: #1b65b5;
  color: white;
  position: absolute;
  left: 20px;
  width: 420px;
  top: 20px;
  z-index: 99;
  border-radius: 15px;
  padding: 10px 10px 5px;
  opacity: 0.7;
  font-size: 16px;
  h4 {
    color: white;
    font-weight: 600;
  }
  ul {
    margin-left: -40px;
    li {
      list-style: none;
      padding: 8px 0;
    }
  }
}
</style>
