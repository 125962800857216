import request from '@/utils/request'
// import { resolve } from 'core-js/fn/promise'

// api地址
const api = {
  all: '/rnt/chargeCabinet',
  save: '/rnt/chargeCabinet',
  update: '/rnt/chargeCabinet',
  info: '/rnt/chargeCabinet/',
  delete: '/rnt/chargeCabinet/',
  deleteBatch: '/rnt/chargeCabinet/batch',
  page: '/rnt/chargeCabinet/page',
  cabinetType: '/sys/dictdata',
  enable: '/rnt/chargeCabinet/enable/',
  dictData: '/sys/dictdata/page',
  cabinetState: '/rnt/chargeCabinetState/page',
  cabinetBox: '/rnt/chargeCabinetBox/page',
  cabinetAlarm: '/rnt/chargeCabinetAlarm/page',
  cabinetBat: '/rnt/chargeCabinetBat/page',
  cabinetRecord: '/rnt/chargeCabinetRecord/page',
  opendoor: '/rnt/chargeCabinet/opendoor',
  enabledoor: '/rnt/chargeCabinet/enabledoor',
  reset: '/rnt/chargeCabinet/reset',
  cabSoc: '/rnt/chargeCabinet/cabSoc',
  cabVol: '/rnt/chargeCabinet/cabVol'
}
/**
 * 换电柜语音音量
 * @param data data
 * @returns {Promise<Object>}
 */
export function cabVol(data) {
  return new Promise((resolve, reject) => {
    request.post(api.cabVol, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 换电柜换电阈值
 * @param data data
 * @returns {Promise<Object>}
 */
export function cabSoc(data) {
  return new Promise((resolve, reject) => {
    request.post(api.cabSoc, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 重启换电柜
 * @param data data
 * @returns {Promise<Object>}
 */
export function reset(data) {
  return new Promise((resolve, reject) => {
    request.post(api.reset, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 禁用仓门
 * @param id id
 * @returns {Promise<Object>}
 */
export function enabledoor(data) {
  return new Promise((resolve, reject) => {
    request.post(api.enabledoor, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 分页查询换电柜记录
 * @param id id
 * @returns {Promise<Object>}
 */
export function openDoor(data) {
  return new Promise((resolve, reject) => {
    request.post(api.opendoor, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 分页查询换电柜记录
 * @param id id
 * @returns {Promise<Object>}
 */
export function cabinetRecord(params) {
  return new Promise((resolve, reject) => {
    request.get(api.cabinetRecord, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 分页查询换电柜电池信息
 * @param id id
 * @returns {Promise<Object>}
 */
export function cabinetBat(params) {
  return new Promise((resolve, reject) => {
    request.get(api.cabinetBat, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 分页查询换电柜告警信息
 * @param id id
 * @returns {Promise<Object>}
 */
export function cabinetAlarm(params) {
  return new Promise((resolve, reject) => {
    request.get(api.cabinetAlarm, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 分页查询换电柜仓门状态
 * @param id id
 * @returns {Promise<Object>}
 */
export function cabinetBox(params) {
  return new Promise((resolve, reject) => {
    request.get(api.cabinetBox, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 分页查询换电柜运行状态
 * @param id id
 * @returns {Promise<Object>}
 */
export function cabinetState(params) {
  return new Promise((resolve, reject) => {
    request.get(api.cabinetState, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id查询
 * @param id id
 * @returns {Promise<Object>}
 */
export function getById(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all(params) {
  return new Promise((resolve, reject) => {
    request.get(api.all, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id查询
 * @param id id
 * @returns {Promise<>}
 */
export function info(id) {
  return new Promise((resolve, reject) => {
    request.get(api.info + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 批量删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteBatch(data) {
  return new Promise((resolve, reject) => {
    request.delete(api.deleteBatch, data).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.put(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}
/**
 * 查询列表
 * 分页查询换电柜协议字典项
 * @param params 参数
 * @returns {Promise<Array>}
 */
export const cabinetType = () => {
  const params = {
    dictCode: 'cabinetProtocol'
  }
  return new Promise((resolve, reject) => {
    request.get(api.cabinetType, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询列表
 * 分页查询字典项
 * @param params 参数
 * @returns {Promise<Array>}
 */
export const dictData = (params) => {
  return new Promise((resolve, reject) => {
    request.get(api.dictData, {
      params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 修改换电柜启用状态
 * @param data
 * @returns {Promise<Array>}
 */
export function enable(data) {
  return new Promise((resolve, reject) => {
    const params = 'enable=' + data.enable
    request.post(api.enable + data.cabinetId, params).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
